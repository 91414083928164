import React from "react";
import "./index.scss";
import logo from '../../images/logo.svg'
import tg_icon from '../../images/footer-tg.svg'
import inst_icon from '../../images/footer-inst.svg'
import vk_icon from '../../images/footer-vk.svg'
import wa_icon from '../../images/footer-wa.svg'
import { useNavigate } from "react-router-dom";

function Footer({color=""}) {

    const to = useNavigate()

    return (
        <div className="footer">
            <div className={"footer-wrapper "+ color}>
                <div class='footer-header'>
                    <img class='footer-logo' src={logo}></img>
                    <a href='tel:+79175711617' class='footer-phone'>+7 917 571 16 17</a>
                    <div class='footer-mess'>
                        <a href='https://t.me/vita_dance_studio'><img class='footer-mess-img' src={tg_icon}></img></a>
                        {/* <a href='inst.com'><img class='footer-mess-img' src={inst_icon}></img></a> */}
                        <a href='https://vk.com/club211203931'><img class='footer-mess-img' src={vk_icon}></img></a>
                        <a href='https://wa.me/79175711617?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%A5%D0%BE%D1%87%D1%83%20%D0%B7%D0%B0%D0%BF%D0%B8%D1%81%D0%B0%D1%82%D1%8C%D1%81%D1%8F%20%D0%BD%D0%B0%20%D0%B7%D0%B0%D0%BD%D1%8F%D1%82%D0%B8%D0%B5'><img class='footer-mess-img' src={wa_icon}></img></a>
                    </div>
                </div>
                <div class='footer-body'>
                    <div class='footer-body-left'>
                        <h2 class='footer-body-h2'>ссылки:</h2>
                        <a class='footer-body-p' href="/vectors" >направления</a>
                        <a class='footer-body-p' href="/locations">филиалы</a>
                        <a class='footer-body-p' href="/teachers">педагоги</a>
                        <a class='footer-body-p' href="/time">стоимость и расписание</a>
                    </div>
                    <div class='footer-body-right'>
                        <h2 class='footer-body-h2'>запись</h2>
                        <a class='footer-body-p' href="https://wa.me/79175711617?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%A5%D0%BE%D1%87%D1%83%20%D0%B7%D0%B0%D0%BF%D0%B8%D1%81%D0%B0%D1%82%D1%8C%D1%81%D1%8F%20%D0%BD%D0%B0%20%D0%B7%D0%B0%D0%BD%D1%8F%D1%82%D0%B8%D0%B5">связаться</a>
                        <a class='footer-body-p' href="https://docs.google.com/forms/d/e/1FAIpQLSfRLyk0fXzeJ1eYO_xWZrfVn9wapX92ZciaUpfxLueBUjQPfw/viewform?usp=sf_link">оставить заявку</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;