import React from "react";
import "./index.scss"
// COMPONENTS
import NavBar from "../../components/Navbar";
import OpenSet from "../../components/openset";
import LogoLand from "../../components/logo-landing";
import Featured from "../../components/featured";
import History from "../../components/history";
import Reviews from "../../components/reviews";
import Topics from "../../components/topics";
import News from "../../components/news";
import Footer from "../../components/footer";

function Page() {


    return (
        <div className="app landing">
            <NavBar/>
            <OpenSet/>
            <LogoLand/>
            <Featured/>
            <History/>
            <Reviews/>
            <Topics/>
            {/* <News/> */}
            <Footer/>
        </div>
    );
}


export default Page;