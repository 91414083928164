import React, { useState } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import galina from "../../images/galina.png";
import julia from "../../images/julia.png";
import anna from "../../images/anna.png";
import ArrowRight from "../../images/arrow-right.svg";

function Reviews() {
  const [ind, setInd] = useState(0);

  const UpdateInd = () => {
    setInd((ind + 1) % 3);
  };
  const data = {
    0: {
      img: galina,
      name: "Галина",
      coment: (
        <p>
          Сюда приходят детишки от 3-х лет маленькими и смешными, но, благодаря,
          нашим замечательным педагогам, они становятся настоящими артистами. В
          студии за время её существования сложился чудесный коллектив, где
          педагоги помогают раскрыть таланты, а старшие девочки опекают младших,
          заботятся о них. Девочки, попадая в «VITA», постепенно осваивают все
          секреты сценического движения и пластики, а затем с удовольствием
          принимают участие в выступлениях и конкурсах. Театр танца «VITA»
          неоднократно принимал участие в танцевальных конкурсах, где становился
          победителем и лауреатом. И всё это благодаря нашему руководителю
          Бутовой Наталье Вячеславовне. В следующем году у нашего дружного
          коллектива юбилей, нам исполняется 10 лет. Хочется пожелать
          дальнейшего творческого развития театру танца «VITA» и школе
          испанского танца
          <br />
          <br />
          С уважением,
          <br />
          Ульянова Галина{" "}
        </p>
      ),
    },1: {
        img: julia,
        name: "Юлия",
        coment: (
          <p>
            В Театре танца  VITA моя дочь занимается уже 10 -ый год. Благодаря танцам, и дружному коллективу, дочка стала более раскрепощенной, общительной. С удовольствием ходит на все занятия, участвует в различных конкурсах, отчётных концертах. Занимается у замечательных хореографах Натальи Вячеславовны и Валерии Павловны. Они профессионалы своего дела, находят подход к каждому ребёнку, очень чуткие, добрые, и в меру строгие, любящие наших детей.
          </p>
        ),
      },
      2: {
        img: anna,
        name: "Анна",
        coment: (
          <p>
            Vita - это не просто танцы! Это образ жизни, это коллектив и поддержка друг друга, это общение не только на занятиях, но и вне их, это выступления на большой сцене, совместные поездки и море новых впечатлений. 
За несколько лет занятий я наглядно вижу как растет уровень моих детей, как они исполняют все более сложные танцевальные композиции. 
Наши прекрасные педагоги вкладывают душу, стараются к каждому ребёнку подобрать «ключик», в тоже время воспитывают в них дисциплину и ответственность.
          </p>
        ),
      },
  };

  return (
    <div className="reviews container">
      <div className="reviews-wrapper">
        <h1>отзывы о нас</h1>
        <div className="review">
          <div className="review-from">
            <img src={data[ind]["img"]} />
            <p>{data[ind]["name"]}</p>
          </div>
          <div className="review-comment">{data[ind]["coment"]}</div>
          <div className="arrows">
            <img onClick={UpdateInd} src={ArrowRight} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reviews;
