import React from "react";
import "./index.scss";
import link_tg from '../../images/link-tg.svg';
import link_wa from '../../images/link-wa.svg';
import logo from '../../images/logo.svg'
import { useNavigate } from "react-router-dom";

function NavBar({color=""}) {

    const to = useNavigate()

    return (
        <div className="container" id="top">
        <div className="navbar">
            <div className={"navbar-wrapper "+ color}>
                <div className="navbar-logo" onClick={()=>{to("/")}}><img className="navbar-logo-img" src={logo}></img></div>
                <div className="navbar-topics">
                    <p className="navbar-topics-p" onClick={()=>{to("/vectors")}}>Направления</p>
                    <p className="navbar-topics-p"  onClick={()=>{to("/locations")}}>Филиалы</p>
                    <p className="navbar-topics-p"  onClick={()=>{to("/teachers")}}>Педагоги</p>
                    <p className="navbar-topics-p"  onClick={()=>{to("/time")}}>Стоимость и расписание</p>
                </div>
                <div className="navbar-links">
                    <a className="navbar-links-tg" href='https://t.me/+79175711617'><img className="navbar-links-tg-img" src={link_tg}></img></a>
                    <a className="navbar-links-wa" href='https://wa.me/79175711617?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%A5%D0%BE%D1%87%D1%83%20%D0%B7%D0%B0%D0%BF%D0%B8%D1%81%D0%B0%D1%82%D1%8C%D1%81%D1%8F%20%D0%BD%D0%B0%20%D0%B7%D0%B0%D0%BD%D1%8F%D1%82%D0%B8%D0%B5'><img className="navbar-links-wa-img" src={link_wa}></img></a>
                    <div className="navbar-links-call">
                        <a className="navbar-links-call-p" href="https://wa.me/79175711617?text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%20%D0%A5%D0%BE%D1%87%D1%83%20%D0%B7%D0%B0%D0%BF%D0%B8%D1%81%D0%B0%D1%82%D1%8C%D1%81%D1%8F%20%D0%BD%D0%B0%20%D0%B7%D0%B0%D0%BD%D1%8F%D1%82%D0%B8%D0%B5">записаться</a>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default NavBar;