import React from "react";
import "./index.scss";
// COMPONENTS
import NavBar from "../../components/Navbar";
import Footer from "../../components/footer";
import Vector from "../../components/vector";
import Vector1 from "../../images/vector1.png";
import Vector2 from "../../images/vector2.png";
import Vector3 from "../../images/vector3.png";
import Vector4 from "../../images/vector4.png";
import Vector5 from "../../images/vector5.png";
import Vector6 from "../../images/vector6.png";
import Vector7 from "../../images/vector7.png";
import Vector8 from "../../images/vector8.svg";
import img0 from "../../images/image.png";
import img1 from "../../images/image-1.png";
import img2 from "../../images/image-2.png";
import img3 from "../../images/image-3.png";
import img4 from "../../images/image-4.png";
import img5 from "../../images/image-5.png";
import img6 from "../../images/image-6.png";
import img7 from "../../images/image-7.png";
import img8 from "../../images/image-8.png";
import img9 from "../../images/image-9.png";
import img10 from "../../images/image-10.png";
import img11 from "../../images/image-11.png";
import img12 from "../../images/image-12.png";
import img13 from "../../images/image-13.png";
import img14 from "../../images/image-14.png";
import img15 from "../../images/image-15.png";
import img16 from "../../images/image-16.png";
import img17 from "../../images/image-17.png";
import img18 from "../../images/image-18.png";
import img19 from "../../images/image-19.png";
import img20 from "../../images/image-20.png";
import img21 from "../../images/image-21.png";
import img22 from "../../images/image-22.png";
import img23 from "../../images/image-23.png";
import img24 from "../../images/image-24.png";
import img25 from "../../images/image-25.png";
import img26 from "../../images/image-26.png";
import img27 from "../../images/image-27.png";

function Page() {
  return (
    <div className="app landing">
      <NavBar color="black" />
      <Vector
        img={Vector1}
        data={[img0, img1, img2, img3]}
        description={
          <p>
            Эстетика и свобода самовыражения в одном ключе. Уникальное развитие
            работы мозговой и физической активности наших учеников через
            современный танец. Мы учим говорить через движение, искать и
            создавать свое. Учим базовым основам в лучших современных
            направлениях: jazz танца, contemporary jazz, афро и танца модерн.
            Стоит только сделать шаг навстречу, чтобы открыть для себя целую
            вселенную красоты, искусства и творчества
          </p>
        }
      />
      <Vector
        img={Vector2}
        data={[img4, img5, img6, img7]}
        description={
          <p>
            Программа подготовки универсального артиста, в основе комплексный
            подход к обучению. Программа включает следующие дисциплины:
            <br />
            партерная гимнастика, растяжка
            <br />
            классический танец
            <br />
            эстрадный танец
            <br />
            современный танец
            <br />
            народный танец (основное направление испанский танец фламенко)
            <br />
            <br />
            Коллектив многократный лауреат всероссийских и международных
            конкурсов, обладатель Гран-При. Концертная деятельность:
            <br />
            отчетные концерты, спектакликонкурсы и фестивали в Москве и выездные
            в других городах творческие лагеря
          </p>
        }
      />
      <Vector
        img={Vector3}
        data={[img8, img9, img10, img25]}
        description={
          <p>
            Обучение испанскому танцу фламенко. В программе:<br/> работа с ритмами
            постановка рук, корпуса, техника дробей, вращений работа с
            предметами (кастаньеты, веер, шаль) подготовка концертных номеров
            Для детей от 7 лет и взрослых
          </p>
        }
      />
      <Vector
        img={Vector4}
        data={[img11, img12, img13, img14]}
        description={
            <p>
            Начальная хореографическая подготовка.
В программе:
ритмика
гимнастика
детский танец
концертная деятельность 

          </p>}
      />
      <Vector
        img={Vector5}
        data={[img15, img16, img17, img18]}
        description={
          <p>На классах будем изучать:<br/>
          откуда начинается движение, и что его запускает, как мы можем проживать эмоции, чувства через движение. 
          <br/><br/>
          Будем использовать инструменты свободы движения и перемещения, различные техники дыхания, ощущать себя, выражать. 
          Подготовка не требуется
          Уровень сложности: любой <br/><br/>
          
          Классы про исследовать, выражать, проживать, исцелять</p>}
      />
      <Vector
        img={Vector6}
        data={[img19, img20, img21, img22]}
        description={
          <p>VRarte <br/>
          (группа по авторской хореографии и постановки) <br/><br/>
          
          Просмотр и подготовка обязательно<br/><br/>
          
          Классы : 2 часа углублённой работы, направленной на создание, изучения, разучивание хореографии
          
          <br/><br/>Цель : развитие способностей, показы, съемки</p>}
      />
      <Vector
        img={Vector7}
        description={
          <p>Йога - стретчинг<br/>
          В основе занятия вид динамической хатха йоги + растяжка<br/><br/>
          
          Уровень сложности - любой<br/><br/>
          
          Продолжительность - 1 часа <br/><br/>
          
          Форма: топ и лосины<br/><br/>
          
          Цель практики: развитие мобильности, гибкости, пластичности всего тела.<br/>
          Данный класс помогает обрести хороший контакт с телом, ощущая каждое движения и себя в этом движение. 
          <br/><br/>
          На занятиях по йога- стретчинг мы планомерно задействуем всё тело: размягчаем, растягиваем, укрепляем.
          <br/><br/>
          Используются различные принципы работы с телом : чередуем статику и динамику, работаем с дыханием</p>}
      />
      <Vector
        img={Vector8}
        data={[img23, img26, img27, img24]}
        description={
          <p>Систематическое многоступенчатое образование по испанскому танцу, фламенко, esfilizada, escuela bolero, с выдачей международного сертификата/диплома, 
            после сдачи экзамена.<br/><br/>Данная программа позволит Вам получить образование по специализациям:<br/><br/>
            1 ступень - танцор (фламенко, испанский танец, школа Bolero)<br/><br/>2 ступень - преподаватель (фламенко, испанский танец, школа Bolero)<br/><br/>
            Обучение строится по принципу от простого к сложному.<br/><br/>Программа рассчитана как на детей с 7 лет, так и на взрослых, начинающих 
            или уже имеющих хореографическую подготовку.</p>}
      />
      {/* <News/> */}
      <Footer color='black' />
    </div>
  );
}

export default Page;
