import React from "react";
import "./index.scss";
import direction_img from '../../images/direction.png'
import places from '../../images/places.png'
import teachers from '../../images/teachers.png'
import prices from '../../images/price.png'
import arrow from '../../images/arrowdownright.svg'
import { useNavigate } from "react-router-dom";

function Main() {


    // const to = useNavigate()

    return (
        <div className="main container">
            <div className="main-wrapper">
                <div class='topics'>
                    <div class='topics-img'><img src={direction_img}></img></div>
                    <div class='topics-text'>
                        <h2 class='topics-text-h2'>направления</h2>
                        <p class='topics-text-p'>широкий выбор направлений для всех возрастов</p>
                        <a href="/vectors" class='topics-text-link'>
                            <div class='topics-text-link-btn-direct'>подробнее</div>
                            <div class='topics-text-link-arrow'><img src={arrow}></img></div>
                        </a>
                    </div>
                </div>
                <div class='topics'>
                    <div class='topics-img'><img src={places}></img></div>
                    <div class='topics-text'>
                        <h2 class='topics-text-h2'>филиалы</h2>
                        <p class='topics-text-p'>находимся в легкодоступных районах Москвы</p>
                        <a href="/locations" class='topics-text-link'>
                            <div class='topics-text-link-btn-places'>подробнее</div>
                            <div class='topics-text-link-arrow'><img src={arrow}></img></div>
                        </a>
                    </div>
                </div>
                <div class='topics'>
                    <div class='topics-img'><img src={teachers}></img></div>
                    <div class='topics-text'>
                        <h2 class='topics-text-h2'>педагоги</h2>
                        <p class='topics-text-p'>профессиональный и заботливый педагогический состав</p>
                        <a  href='/teachers' class='topics-text-link'>
                            <div class='topics-text-link-btn-teachers'>подробнее</div>
                            <div class='topics-text-link-arrow'><img src={arrow}></img></div>
                        </a>
                    </div>
                </div>
                <div class='topics'>
                    <div class='topics-img'><img src={prices}></img></div>
                    <div class='topics-text'>
                        <h2 class='topics-text-h2-price'>Стоимость и расписание</h2>
                        <a href='/time' class='topics-text-link'>
                            <div class='topics-text-link-btn-price'>подробнее</div>
                            <div class='topics-text-link-arrow'><img src={arrow}></img></div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Main;