import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import Arrow from "../../images/arrow.svg"

function Reviews() {

    return (
        <div className="history container">
            <div className="history-wrapper">
                <h1>существуем<br className="mobile"/> с 2010 года</h1>
                <img src={Arrow}/>
            </div>
        </div>
    )
}

export default Reviews;