import './styles/App.scss';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router';
import {BrowserRouter, Route, Routes, useParams, useSearchParams} from 'react-router-dom';

// IMPORT PAGES
import Landing from './pages/Landing'
import Vectors from './pages/Vectors'
import Locations from './pages/Locations'
import Time from './pages/Time'
import Teachers from './pages/Teachers'



function App() {


    return (
        <div className="App">
            <Routes>
                <Route path='/' element={<Landing />}/>
                <Route path='/vectors' element={<Vectors />}/>
                <Route path='/locations' element={<Locations />}/>
                <Route path='/time' element={<Time />}/>
                <Route path='/teachers' element={<Teachers />}/>
            </Routes>
        </div>
    );
}

export default App;
