import React, { useState } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import feat1 from "../../images/featured/1.png";
import feat2 from "../../images/featured/2.png";
import feat3 from "../../images/featured/3.png";
import feat4 from "../../images/featured/4.png";
// import { Slider } from "infinite-react-carousel";

// import Swiper from 'react-id-swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Slider() {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
    //   pagination={{
    //     clickable: true,
    //   }}
    //   navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className="row-featured"
    >
      <SwiperSlide>
        <div className="cell-featured">
          <img src={feat1} />
          <p>
            сертифицированные международные
            <br />
            программы
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="cell-featured">
          <img src={feat2} />
          <p>творческие группы</p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        {" "}
        <div className="cell-featured">
          <img src={feat3} />
          <p>
            участие в фестивалях, конкурсах.
            <br />
            творческие лагеря
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="cell-featured">
          <img src={feat4} />
          <p>концерты и спектакли</p>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

function Featured() {
  return (
    <div className="featured container">
      <div className="featured-wrapper">
        <Slider />
        <div className="row-featured desktop">
          <div className="cell-featured">
            <img src={feat1} />
            <p>
              сертифицированные международные
              <br />
              программы
            </p>
          </div>

          <div className="cell-featured">
            <img src={feat2} />
            <p>творческие группы</p>
          </div>
        </div>
        <div className="row-featured desktop">
          <div className="cell-featured">
            <img src={feat3} />
            <p>
              участие в фестивалях, конкурсах.
              <br />
              творческие лагеря
            </p>
          </div>

          <div className="cell-featured">
            <img src={feat4} />
            <p>концерты и спектакли</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Featured;
