import React from "react";
import "./index.scss";
// COMPONENTS
import NavBar from "../../components/Navbar";
import Footer from "../../components/footer";
import Vector from "../../components/vector";
import Locations from "../../images/locations.png";

import LocationDesktop from "../../images/locations-desktop.png";


function Page() {
  return (
    <div className="app">
      <NavBar color="purple" />
      <div className="container">
      <img className="locations" src={Locations}/>
      <img className="locations desktop" src={LocationDesktop}/></div>
      <Footer color='purple'/>
    </div>
  );
}

export default Page;
