import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";

function OpenSet() {
  return (
    <div className="container openset">
      <div className="openset-wrapper">
        <div className="description desktop" >
          <h1>
            открыт набор
            <br />
            2024/2025
          </h1>
          <p>vita dance studio приглашает
          <br />всех желающих стать частью<br />семьи!</p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSfRLyk0fXzeJ1eYO_xWZrfVn9wapX92ZciaUpfxLueBUjQPfw/viewform?usp=sf_link" className="button">оставить заявку</a>
        </div>
        <div className="description mobile">
          <h1>
            набор
            <br />
            2024/2025
          </h1>
          <a className="button" href="https://docs.google.com/forms/d/e/1FAIpQLSfRLyk0fXzeJ1eYO_xWZrfVn9wapX92ZciaUpfxLueBUjQPfw/viewform?usp=sf_link"><p>оставить заявку</p></a>
      </div>
    </div> </div>
  );
}

export default OpenSet;
