import React from "react";
import "./index.scss";
import butova from '../../images/butova.svg'
import silina from '../../images/silina.svg'
import ruleva from '../../images/ruleva.svg'
import zhukova from '../../images/zhukova.svg'
import sert1 from '../../images/butova-sert-1.svg'
import sert2 from '../../images/butova-sert-2.svg'
// COMPONENTS


function Page() {
  return (
    <div className="teachers">
        <div class='teachers-wrapper'>
            <h1 class='teachers-h1'>педагоги</h1>
            <div class='teacher-card'>
                <div class='teacher-topic'>
                    <div class='topic-photo'>
                        <img class='photo-img' src={butova}></img>
                        <h1 class='photo-h1'>Наталья Бутова</h1>
                    </div>
                    <div class='topic-txt'>
                        <p class='topic-p'>Знакомьтесь: основатель и  руководитель Vita Dance Studio - Бутова Наталья Вячеславовна, профессиональный хореограф-постановщик и педагог (испанский танец, фламенко, эстрадный танец, детская хореография).  </p>
                        <p class='topic-p'>Наш руководитель не только учитель, режиссер-постановщик, сценарист, но и артист. Когда есть возможность, выступает для души, и даже получает награды, например - звание лауреата Московского фестиваля художественного творчества «Признание» в номинации «Мастер-виртуоз».</p>
                        <p class='topic-p'>Номера, поставленные Натальей Вячеславовной в жанре испанского танца, фламенко, стилизованного, эстрадного танца, неоднократно получали награды и призовые места на фестивалях и конкурсах.</p>
                        <p class='topic-p'>Образование<br/>Наталья Вячеславовна обучалась в школе танца при Академии танца Н. Нестеровой, затем в Российском государственном социальном университете, в Московском городском педагогическом университете, а также в Московском государственном гуманитарном университете им. М. Шолохова (факультет культуры и музыкального искусства, специальность хореограф-постановщик). А также прошла двухгодичные  курсы повышения квалификации "Спутник" при Российском Институте Театрального Искусства (ГИТИС).</p>
                        <p class='topic-p'>С 2008 г. Наталья Вячеславовна начала изучать искусство фламенко  во Фламенкоцентре у Екатерины Че, а также в студии  Светланы Савельевой "Андалусия".<br/>Также обучалась на мастер-классах в Испании, школа Taller flamenco (Севилья) у мастеров Angel Atienza, Lucia «La Pinona», и на мастер-классах в Москве: у мастеров Belen Maya, Manuela Barrios, Juan Aguirre, Jason Damian, Sara Santos Romero, Julian Vicente Valdes, Javier Malaguilla, Florencia O'Ryan Zuniga, Laura Segovia, Manuela Barrios, Ricardo Castro Romero</p>
                        <p class='topic-p'>С 2011 года Наталья Вячеславовна ежегодно проходит обучение в Spanish Dance Society (Международная ассоциация испанских танцев).<br />В 2024 году стала полуфиналистом Московского городского профессионального конкурса педагогического мастерства и общественного признания «Педагоги года Москвы» а номинации «Сердце отдаю детям».</p>
                    </div>
                </div>
                <div class='teacher-achives'>
                    <img class='teacher-sert-img' src={sert1}></img>
                    <img class='teacher-sert-img' src={sert2}></img>
                </div>
                <div class='teacher-dances'>
                    <h1 class='teacher-dances-h1'>преподаваемые дисциплины:</h1>
                    <div class='teacher-dances-list'>Детский танец, классическая хореография,  эстрадный танец, фламенко,  SDS (Spanish Dance Society)</div>
                </div>
            </div>


            <div class='teacher-card'>
                <div class='teacher-topic'>
                    <div class='topic-photo'>
                        <img class='photo-img' src={silina}></img>
                        <h1 class='photo-h1'>Светлана Силина</h1>
                    </div>
                    <div class='topic-txt'>
                        <p class='topic-p'>Наш куратор от международной ассоциации испанского танца - Светлана Сергеевна Силина, представитель SDS. Она регулярно приезжает в Москву и проводит мастер-классы для учеников студии. </p>
                        <p class='topic-p'>Светлана  в течение 10 лет обучалась классическому балету в Москве у выпускников Большого театра, выступала в классических партиях.</p>
                        <p class='topic-p'>Окончила Московский университет по специальности "Культурология" и получила квалификацию мэтра классического балета и преподавателя русского народного танца.</p>
                        <p class='topic-p'>Светлана начала преподавать в одном из ведущих российских фольклорных коллективов для детей: классический балет и русские народные танцы. В этот период она также выступала с русским фольклорным коллективом.</p>
                        <p class='topic-p'>В 1996 году Светлана переехала на Мальту и в течение 17 лет преподавала в Центральной академии балета.</p>
                        <p class='topic-p'>Светлана Силина также имеет статус ассоциированного члена Императорского общества преподавателей танцев, факультета Чеккетти, а также IdB (I) и IdB (II), PdB Испанского танцевального общества.</p>
                        <p class='topic-p'>В 2004 году она была назначена заведующей кафедрой испанского танца в Мальтийском центре драмы и музыки. В 2017 году она стала заведующей кафедрой испанского танца в консерватории танца имени Йохане Касабене. Она по-прежнему занимает обе должности.</p>
                        <p class='topic-p'>На Мальте Светлана была хореографом-постановщиком нескольких танцевальных постановок, а также выступала в различных балетных постановках, например, "Русалочка", "Собор Парижской Богоматери", "Дон Кихот", "Щелкунчик" и "Сон в летнюю ночь". Хореография включала классический балет, русские народные, восточные танцы, венгерские танцы, мексиканские танцы, а также классические испанские танцы и фламенко. Не раз выступала на Мальтийском телевидении.</p>
                        <p class='topic-p'>Она также поставила хореографию для сцены в фильме “Юлий Цезарь” (2002).</p>
                        <p class='topic-p'>Имея за плечами 30-летний опыт преподавания как взрослым, так и детям, Светлана считает, что важно продолжать собственное профессиональное развитие, чтобы расти как профессионал, а также расширять свои знания.</p>
                    </div>
                </div>
                <div class='teacher-dances'>
                    <h1 class='teacher-dances-h1'>преподаваемые дисциплины:</h1>
                    <div class='teacher-dances-list'>куратор SDS (Spanish Dance Society) </div>
                </div>
            </div>


            <div class='teacher-card'>
                <div class='teacher-topic'>
                    <div class='topic-photo'>
                        <img class='photo-img' src={ruleva}></img>
                        <h1 class='photo-h1'>Валентина Рулева</h1>
                    </div>
                    <div class='topic-txt'>
                        <p class='topic-p'>Родилась и училась в хореографическом училище (КГХУ) в Красноярске (классическое отделение), где получила сильную базу для дальнейшего развития</p>
                        <p class='topic-p'>По настоящему нашла и раскрыла себя в современный хореографии. Развивалась, как танцовщик и педагог в направлении джаз-модерн в Москве</p>
                        <p class='topic-p'>Обучалась на многочисленных мастер классах у известных зарубежных хореографов, выступала на различных площадках Москвы.<br/>Получила Диплом педагог-хореограф/руководитель коллектива (МГГУ, институт имени М.А Шолохова г.Москва)<br/>Сертифицированный фитнес тренер по (джаз-модерн)<br/>Сертифицированный инструктор по развитие стопы и голеностопного сустава</p>
                        <p class='topic-p'>Танцовщик| Педагог | хореограф|постановщик, учитель йоги, wellness Креатор, онлайн тренер<br/>(Балет, джаз, модерн, контемпорари, йога, стретчинг, осознанное движение )<br/>В танцах - 30 лет<br/>Педагогический стаж- 17 лет<br/>Опыт постановок -10 лет<br/>Основала и и руководила студией - «VRdance» - 12 лет<br/>Совладелец - «студия RK»(Алания)</p>
                        <p class='topic-p'>За плечами более 1000 учеников, огромное количество групповых и индивидуальных классов, работа с фигуристками, гимнастками, танцовщиками разного уровня, как любителями, так и профессиональными по развитию балетных данных, основам хореографии, постановкам номеров.</p>
                        <p class='topic-p'>Живёт в России (Москва) работает на 2 страны : Россия (Москва) - Турция (Алания/Анталия)</p>
                        <p class='topic-p'>Проведения курсов, интенсивов, МК по contemporary dance & floorwork techniques</p>
                        <p class='topic-p'>Имея огромный опыт и знания в сфере танцевального искусства.<br/>Валентина хочет передать ученикам свои знания, научить владеть своим телом, выражать себя и подавать через свободу движения, наполняя душа и не травмируя тела (к чему сама пришла путём сильной трансформации, долгих поисков и исследований тела и движения)<br/>«Танцевать может каждый!<br/>Красивому, техничному и в тоже время свободному движению нужно учиться.»</p>
                        <p class='topic-p'>Её классы - это настоящее погружение и познания самого себя, возможностей своего тела и красоты движения!!!<br/>Основные особенности в подаче материала и ведение классов - внимание к деталям, перемещение в пространстве, вращение, лёгкие прыжки и красивые линии!</p>
                    </div>
                </div>
                <div class='teacher-dances'>
                    <h1 class='teacher-dances-h1'>преподаваемые дисциплины:</h1>
                    <div class='teacher-dances-list'>Современный танец (contemporary jazz), классический танец, боди- балет,  авторская хореография, свободное движение, йога- стретчинг</div>
                </div>
            </div>


            <div class='teacher-card'>
                <div class='teacher-topic'>
                    <div class='topic-photo'>
                        <img class='photo-img' src={zhukova}></img>
                        <h1 class='photo-h1'>Валерия Жукова</h1>
                    </div>
                    <div class='topic-txt'>
                        <p class='topic-p'>Педагог-хореограф современных направлений танца. Валерия имеет педагогический стаж 12 лет, практический 20 лет.<br/>
Образование:<br/>
Московский губернский колледж искусств (МГКИ) - "Современный танец. Руководитель творческого коллектива"<br/>
Центр Йоги Прана - "Педагог Хатха-Йоги"<br/>
Училась у значимых педагогов хореографической школы: Лутошкина Светлана Анатольевна- Джаз танец; Герасимова Юлия Александровна - Народный танец; Акимов Дмитрий Михайлович - Танец Модерн; Акчурин Николай Яковлевич - Классический танец; Елкина Марина Викторовна - Методика преподавания.<br/>
Учителя по практике йоги: Михаил Галаев, Сергей Агапкин, Сергей Митропольский, Ольга Потапова, Александр Титов.</p>
                        <p class='topic-p'>Работала в Академии при Президенте РФ РАНХиГС  хореографом, тренером Хатха-Йоги и ОФП. Была артистом балета 1 категории. Работала приглашенным Хореографом с различными коллективами по России.<br/>
                        Также Валерия продолжает посещать регулярно лекции, мастер-классы, семинары, повышая свои навыки и квалификации. Для неё является важным давать своим ученикам всё самое лучшее. Новые, актуальные и качественные знания, которые всесторонне развивают ученика и ведут к результату. Валерия работает в своей методике с комплексным подходом к каждому ученику.</p>
                        <p class='topic-p'>Основные направления современной хореографии, которые осваивают учащиеся коллектива: " Jazz, Afro, Modern, Hip-hop, Импровизация"</p>
                        <p class='topic-p'>Валерия: "Для меня важно, чтобы ученики всесторонне развивались, получали качественный материал. Увидели новые возможности своего тела, а также получали удовольствие от процесса. Мы рады каждому ученику, научим двигаться красиво и с любовью к тому, что делаете. Приходите и станьте частью нашей семьи"</p>
                    </div>
                </div>
                <div class='teacher-dances'>
                    <h1 class='teacher-dances-h1'>преподаваемые дисциплины:</h1>
                    <div class='teacher-dances-list'>Современный танец (jazz, modern, afro, hip hop), йога</div>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Page;
