import React from "react";
import "./index.scss";
// COMPONENTS
import NavBar from "../../components/Navbar";
import Footer from "../../components/footer";
import Teachers from "../../components/teachers";

import LocationDesktop from "../../images/locations-desktop.png";


function Page() {
  return (
    <div className="app">
      <NavBar color="dark-purple" />
      <Teachers />
      <Footer color='dark-purple'/>
    </div>
  );
}

export default Page;
