import React from "react";
import "./index.scss"
// COMPONENTS
import NavBar from "../../components/Navbar";
import Time from "../../components/Time";
import Footer from "../../components/footer";


function Page() {


    return (
        <div className="app landing">
            <NavBar color='blue' />
            <Time />
            <Footer color='blue' />
        </div>
    );
}


export default Page;