import React from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/logoland.png"


function LogoLand() {

    return (
        <div className="container logo">
            <div className="logo-wrapper">
                <div className="logo-image"><img src={Logo} className="image"></img></div>
                <h1 className="logo-title">vita dance<br/>
                studio</h1>
            </div>
        </div>
    )
}

export default LogoLand;