import React from "react";
import "./index.scss";

import { useNavigate } from "react-router-dom";

function Time({ color = "" }) {
  return (
    <div className="time">
      <div className={"time-wrapper " + color}>
        <h1 class="time-h1">Филиал Ивара</h1>
        <div class="time-h1-div">
          <div class="time-h1-divv">идет набор</div>
        </div>

        <h3 class="time-p-bold">Фламенко 7+</h3>
        <p class="time-p">Понедельник, среда 17.00-18.00</p>
        <h3 class="time-p-bold">Современный танец группа 1. 5-7 лет</h3>
        <p class="time-p">Вторник, четверг 15.00-16.00</p>
        <h3 class="time-p-bold">Современный танец группа 2. 8-12 лет</h3>
        <p class="time-p">Вторник, четверг 16.00-17.00</p>
        <h3 class="time-p-bold">Современный танец группа 3. 11-13 лет</h3>
        <p class="time-p">Понедельник, среда 16.00-17.00</p>
        <h3 class="time-p-bold">Современный танец группа 4. 14+</h3>
        <p class="time-p">Понедельник, среда 17.00-18.00</p>

        <h2 class="time-h2">Взрослые</h2>

        <h3 class="time-p-bold">Фламенко начинающие</h3>
        <p class="time-p">Понедельник, среда 18.00-19.00</p>
        <h3 class="time-p-bold">Современный танец</h3>
        <p class="time-p">Вторник, четверг 12.00-13.00</p>
        <h3 class="time-p-bold">Свободное движение</h3>
        <p class="time-p">Понедельник, среда 18.00-19.00</p>
        <h3 class="time-p-bold">Стретчинг</h3>
        <p class="time-p">Понедельник, среда 15.00-16.00</p>
        <h3 class="time-p-bold">Йога</h3>
        <p class="time-p">Вторник, четверг 13.00-14.00</p>

        <h1 class="time-h1 slogan">
          записывайтесь
          <br />
          на пробное занятие!
        </h1>

        <h1 class="time-h1 marg">Филиал Планета</h1>

        <h3 class="time-p-bold">Группа Витаминки 4-6 лет</h3>
        <p class="time-p">Вторник, четверг 17.30-18.15</p>
        <h3 class="time-p-bold">Группа Витамин 6-10 лет (идёт набор)</h3>
        <p class="time-p">
          Понедельник 13.40-15.00
          <br />
          Пятница 14.15-15.00
        </p>
        <h3 class="time-p-bold">Театр танца Vita (идёт набор)</h3>
        <p class="time-p">
          Младшая гпуппа 6-8 лет
          <br />
          Вторник, четверг 13.50-15.10
          <br />
          Пятница 13.30-14.15
        </p>
        <h3 class="time-p-bold">Средняя группа 9-13 лет</h3>
        <p class="time-p">
          Понедельник 16.00-17.20
          <br />
          Четверг 15.10-16.30
          <br />
          <div className="ital">Современный танец (факультатив)</div>
          Пятница 16.00-17.30
        </p>
        <h3 class="time-p-bold">Старшая группа</h3>
        <p class="time-p">
          Вторник, четверг 19.15-20.30
          <br />
          <div className="ital">Современный танец (факультатив)</div>
          Пятница 18.30-20.00
        </p>
        <h3 class="time-p-bold">Современный танец 10-17 лет (идёт набор)</h3>
        <p class="time-p">
          Понедельник 17.30-18.45
          <br />
          Пятница 17.30-18.30
        </p>
        <h2 class="time-h2-spain">Школа испанского танца</h2>
        <h3 class="time-p-bold">Младшая группа 8-10 лет</h3>
        <p class="time-p">
          Вторник 15.45-16.30
          <br />
          Пятница 15.15-16.00
        </p>
        <h3 class="time-p-bold">Средняя группа 11-14 лет</h3>
        <p class="time-p">Вторник, четверг 16.30-17.15</p>
        <h3 class="time-p-bold">Старшая группа 14-17 лет</h3>
        <p class="time-p">Вторник, четверг 18.30-19.15</p>
        <h2 class="time-h2">Взрослые</h2>
        <h3 class="time-p-bold">Фламенко</h3>
        <p class="time-p">Вторник, четверг 11.00-12.00</p>
        <h3 class="time-p-bold">Современный танец (идет набор)</h3>
        <p class="time-p">Понедельник, среда 19.00-20.00</p>
        <h3 class="time-p-bold">Йога (идет набор)</h3>
        <p class="time-p">Понедельник, среда 20.00</p>
        <h3 class="time-p-bold">Современный танец 16+ (идет набор)</h3>
        <p class="time-p">Суббота 12.00-13.30</p>
        <h3 class="time-p-bold">Творческая группа 16+ (идет набор)</h3>
        <p class="time-p">Суббота 13.30-15.00</p>
        <h3 class="time-p-bold">Свободное движение 18+ (идет набор)</h3>
        <p class="time-p">Суббота 15.30-17.00</p>
        <div class="div-for-margin"></div>
        <h1 className="h1-price">Стоимость</h1>
        <h2 className="h2-price">Ивара</h2>
        <p className="p-price">
          4 занятия в месяц (1 час) 3200 руб.<br/>8 занятий в месяц (1 час) 5600
          руб.<br/>12 занятий в месяц (1 час) 7200 руб.
        </p>
        <p className="p-price-bold marg">
          Разовое занятие (1 час) 1000 руб.<br/>Индивидуальное занятие (1 час) 3000
          руб.
        </p>
        <h2 className="h2-price">Планета</h2>
        <h3 className="h3-price-bold">Дети:</h3>
        <p className="p-price">8 занятий в месяц (45 мин) 4600 руб</p>
        <h3 className="h3-price-bold">Взрослые:</h3>
        <p className="p-price-bold">Фламенко</p>
        <p className="p-price">Абонемент 8 занятий (1 час) 5000 руб</p>
        <p className="p-price-bold">Современный танец</p>
        <p className="p-price">Абонемент 8 занятий (1 час) 5600
        руб.</p>
        <p className="p-price-bold">Йога</p>
        <p className="p-price marg2x">Абонемент 8 занятий (1 час) 6400
        руб</p>
        <p className="p-price-bold">Разовое занятие (1 час) 1000 руб.<br/>

Абонемент 4 занятия (1.5 часа) 4800
руб.<br/>
Разовое занятие (1.5 часа) 1500
руб.<br/>
Индивидуальное занятие (1 час) 3000 руб</p>
<div class="div-for-margin"></div>
      </div>
    </div>
  );
}

export default Time;
