import React, { useState } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import feat1 from "../../images/featured/1.png";
import feat2 from "../../images/featured/2.png";
import feat3 from "../../images/featured/3.png";
import feat4 from "../../images/featured/4.png";
// import { Slider } from "infinite-react-carousel";

// import Swiper from 'react-id-swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

function Slider({cover, data}) {
  return (
    <Swiper
      spaceBetween={30}
      centeredSlides={true}
      // autoplay={{
      //   delay: 3000,
      //   disableOnInteraction: false,
      // }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="row-featured"
    >
      <SwiperSlide className="slide"><div className="slide"><img src={cover}/></div></SwiperSlide>
        {data.map((e)=><SwiperSlide><img src={e}/></SwiperSlide>)}
        
      
    </Swiper>
  );
}

function Vector({img, description, data=[]}) {
  return (
    <div className="vector container">
      <div className="vector-wrapper">
        
        <div className="vector-cell">
          {data.length === 0 ? <Slider data={[]} cover={img}/>: <Slider data={data} cover={img}/>}
          <div className="description">{description}</div>
        </div>
      </div>
    </div>
  );
}

export default Vector;
